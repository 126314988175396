<template>
  <v-card class="pr-5 pl-10" elevation="0">
    <v-breadcrumbs class="ml-10 ma-0 pa-0 mb-5" :items="links" divider=">" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href">
          <p class="caption ma-0" style="color: #8739eb; text-decoration: none">
            {{ item.text }}
          </p>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-card-title class="ml-8 mb-3 ma-0 pa-0">
      <div class="d-flex">
        <v-btn icon @click="close"><v-icon small>mdi-arrow-left</v-icon></v-btn>
        <div class="d-flex flex-column align-start mt-1">
          <p class="text-body-1 ma-0 ml-1 font-weight-bold">{{ showName() }}</p>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card elevation="0" class="pl-10">
      <v-card-title class="d-flex align-center mt-5 mb-5 pl-7">
        <p class="text-body-1 ma-0 font-weight-bold mr-8">Datos generales</p>
        <v-btn small class="text-none caption" rounded outlined color="#8739EB" elevation="0" v-if="!edit"
          @click="edit = !edit">
          <v-icon class="mr-1" small color="#8739EB">mdi-pencil-outline</v-icon>
          Editar Candidato
        </v-btn>
        <v-btn small class="text-none caption mr-8" rounded outlined color="#8739EB" elevation="0" v-if="edit"
          @click="(edit = !edit), updateCandidate(), setNameTitle()">
          <v-icon class="mr-1" small color="#8739EB">mdi-content-save</v-icon>
          Guardar cambios
        </v-btn>
        <v-btn small class="text-none caption" rounded outlined color="#8739EB" elevation="0" v-if="edit"
          @click="(edit = !edit), cancel()">
          <v-icon class="mr-1" small color="#8739EB">mdi-close-circle</v-icon>
          Cancelar
        </v-btn>
      </v-card-title>
      <v-alert dense text type="success" transition="scale-transition" v-if="candidateEdited">
        <p class="ma-0 pa-0 caption">¡Datos modificados con éxito!</p>
      </v-alert>
      <v-card elevation="0" class="mb-16 ml-4 d-flex justify-center">
        <v-card class="d-flex flex-column" width="100%" elevation="0">
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-account-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Nombre</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light text-capitalize" v-model="name"
                :readonly="!edit">
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-account-group-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Edad</p>
              </v-row>
            </v-col>

            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left mt-2" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 text-left d-flex caption ma-0 font-weight-light" v-if="edit" v-model="age"
                :readonly="!edit">
              </v-text-field>
              <p class="caption pa-0 ma-0 ml-6 text-left" style="width: 10rem" v-if="!edit">
                {{
                  this.age === 0
                  ? "Es menor de edad"
                  : this.age !== 1
                    ? `${this.age} años`
                    : "Es mayor de edad"
                }}
              </p>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-email-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Correo</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="email" :readonly="!edit">
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-clock-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Cita</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="appointment" :readonly="!edit">
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-school-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Estudios</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-select :items="degrees" v-model="degree" :filled="edit" color="#A3A1FB" :disabled="edit" rounded
                hide-details class="mt-n4 mb-n4 caption ma-0 font-weight-light" :readonly="!edit" dense
                :append-icon="icon"></v-select>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-translate</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Idioma</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-select :items="languages" v-model="language" :filled="edit" :disabled="edit" rounded hide-details
                class="mt-n4 mb-n4 caption ma-0 font-weight-light" :readonly="!edit" dense :append-icon="icon"></v-select>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-city-variant-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Ciudad</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="city" :readonly="!edit">
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-phone-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Telefono</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="phoneNumber" :readonly="!edit">
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-clock-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                  Disponibilidad
                </p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="availability" :readonly="!edit">
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-briefcase-check-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                  Experiencia
                </p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <div v-if="edit">
                <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="experience" :readonly="!edit">
              </v-text-field>
              </div>
              <div v-else>
                <div class="ms-5 mt-n4 mb-n4 d-flex caption ma-0 font-weight-light text-justify">{{ experience }}</div>
              </div>
             
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-card-account-details-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                  Tipo de Licencia
                </p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-text-field :filled="edit" rounded dense hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="license_type" :readonly="!edit">
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-information-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                  Información Extra
                </p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-wrap" style="width: 80%" :loading="loading">
              <div v-if="edit">
                <v-text-field :filled="edit" rounded dense hide-details
                  class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light"
                   v-model="extra_information"
                  :readonly="!edit">
                </v-text-field>
              </div>
              <div v-else>
                <div class="ms-5 mt-n4 mb-n4 d-flex caption ma-0 font-weight-light text-justify" >{{ extra_information }}</div>
              </div>


            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="2">
              <v-row class="pl-5">
                <v-icon color="#8739EB" small>mdi-account-check-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Estatus</p>
              </v-row>
            </v-col>
            <v-skeleton-loader type="list-item" class="ma-0 pa-0 text-left" style="width: 80%" :loading="loading">
              <v-select :items="statusC" v-model="estado" :filled="edit" :disabled="edit" rounded hide-details
                class="mt-n4 mb-n4 caption ma-0 font-weight-light" :readonly="!edit" dense :append-icon="icon"></v-select>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
        </v-card>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import ReturnPage from "../../mixins/ReturnPage";
import LoadCard from "../../mixins/LoadCard";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  mixins: [ReturnPage, LoadCard],
  data: () => ({
    edit: false,
    estado: "",
    name: "",
    email: "",
    appointment: "",
    degree: "",
    city: "",
    phoneNumber: "",
    nameTitle: "",
    language: "Español",
    availability: "",
    experience: "",
    vacancyName: "",
    zoneName: "",
    VacancyCreatedBy: "",
    icon: "",
    age: "",
    extra_information: "",
    license_type: "",
    languages: [{ value: 0, text: "Español" }],
    degrees: [
      { value: 0, text: "Sin estudios" },
      { value: 1, text: "Primaria Trunca" },
      { value: 2, text: "Primaria terminada" },
      { value: 3, text: "Secundaria trunca" },
      { value: 4, text: "Secundaria terminada" },
      { value: 5, text: "Preparatoria trunca" },
      { value: 6, text: "Preparatoria terminada" },
      { value: 7, text: "Universidad trunca" },
      { value: 8, text: "Universidad terminada" },
      { value: 9, text: "Primaria Trunca" },
      { value: 10, text: "Maestria trunca" },
      { value: 11, text: "Maestria" },
    ],
    statusC: [
      { value: 0, text: "Aceptado" },
      { value: 1, text: "Rechazado" },
      { value: 2, text: "Citado" },
      { value: 3, text: "Acudio a cita" },
      { value: 4, text: "No Acudio a Cita" },
      { value: 5, text: "Contratado" },
      { value: 6, text: "No Contratado" },
    ],
    links: [
      {
        disabled: false,
        href: "/vacancies",
      },
      {
        text: "Detalle",
        disabled: false,
        disabled: "/vacancies/:id",
      },
      {
        text: "Candidatos",
        disabled: true,
      },
      {
        text: "Detalle",
        disabled: true,
      },
    ],
    tab: null,
  }),
  methods: {
    ...mapActions(["requestCandidateById", "updateCandidateById"]),
    showLanguage() {
      return this.language;
    },
    showName() {
      //this.NameTitle = this.name
      return this.nameTitle;
    },
    setNameTitle() {
      this.nameTitle = this.name;
    },
    ageComprobation() {
      // console.log(this.$store.state.candidates.ageCandidate);
      return this.$store.state.candidates.ageCandidate;
    },
    updateCandidate() {
      const payload = {
        candidate_id: this.$route.params.id,
        candidate_city: this.city,
        candidate_education: this.degree,
        candidate_email: this.email,
        candidate_appointment: this.appointment,
        candidate_fullname: this.name,
        candidate_phone_number: this.phoneNumber,
        candidate_messenger_id: this.getCandidateData.candidate_messenger_id,
        candidate_age: this.getCandidateData.candidate_age,
        candidate_availability: this.getCandidateData.candidate_availability,
        candidate_experience: this.getCandidateData.candidate_experience,
        candidate_license_type: this.getCandidateData.license_type,
        candidate_status: this.estado,
        candidate_company_bmsg_id:
          this.getCandidateData.candidate_company_bmsg_id,
        candidate_recruitment_source:
          this.getCandidateData.candidate_recruitment_source,
        candidate_zone_name: this.getCandidateData.candidate_zone_name,
        extra_information: this.getCandidateData.candidate_extra_information,
      };
      this.updateCandidateById(payload);
    },
  },
  computed: {
    ...mapGetters(["getCandidateData", "getVacancyData"]),
    ...mapState(["editedCandidate"]),
    candidateEdited() {
      return this.editedCandidate;
    },
  },
  created() {
    this.requestCandidateById(this.$route.params.id);
  },

  watch: {
    getCandidateData() {
      this.name = this.getCandidateData.candidate_fullname;
      this.nameTitle = this.getCandidateData.candidate_fullname;
      this.email = this.getCandidateData.candidate_email;
      this.appointment = this.getCandidateData.candidate_appointment;
      this.degree = this.getCandidateData.candidate_education;
      this.city = this.getCandidateData.candidate_city;
      this.phoneNumber = this.getCandidateData.candidate_phone_number;
      this.language = 0;
      this.availability = this.getCandidateData.candidate_availability;
      this.experience = this.getCandidateData.candidate_experience;
      this.vacancyName = this.getCandidateData.candidate_vacancy_name;
      this.estado = this.getCandidateData.candidate_status;
      this.zoneName = this.getCandidateData.candidate_zone_name;
      this.VacancyCreatedBy =
        this.getCandidateData.candidate_vacancy_created_by;
      this.age = this.getCandidateData.candidate_age;
      this.extra_information = this.getCandidateData.candidate_extra_information;
      this.license_type = this.getCandidateData.candidate_license_type;
    },
    candidateEdited() {
      setTimeout(() => this.$store.commit("EDITED_CANDIDATE", false), 3000);
    },
  },
  mounted() { },
  beforeUpdate() { },
  updated() { },
};
</script>

<style></style>
